/*------------
    RESET
------------*/
@import 'reset';
@import 'bootstrap/bootstrap-override';
@import 'notyf/notyf';

/*-------------
    GENERAL
-------------*/
@import 'font';
@import 'color';
@import 'color-theme';
@import 'color-theme-custom';
@import 'form';
@import 'icon';
@import 'grid';
@import 'general';
@import 'button';
@import 'utils';
@import 'table';
@import 'widget';
@import 'loader';
@import '404';

/*---------------
    COMPONENT
---------------*/
@import 'picture/main';
@import 'progress-stat/main';
@import 'progress-arc/main';
@import 'user-avatar/main';
@import 'user-status/main';
@import 'cart-item-preview/main';
@import 'form-box/main';
@import 'landing/main';
@import 'dropdown-box/main';
@import 'dropdown-navigation/main';
@import 'navigation-widget/main';
@import 'chat-widget/main';
@import 'profile-header/main';
@import 'post-preview/main';
@import 'post-comment/main';
@import 'post-open/main';
@import 'product-preview/main';
@import 'stream-box/main';
@import 'video-box/main';
@import 'video-status/main';
@import 'content-actions/main';
@import 'user-preview/main';
@import 'album-preview/main';
@import 'popup-box/main';
@import 'badge-item/main';
@import 'streamer-box/main';
@import 'calendar/main';
@import 'event-preview/main';
@import 'quest/main';
@import 'forum-post/main';
@import 'slider-panel/main';
@import 'chart/main';
@import 'demo-box/main';

/*-------------
    LAYOUT
-------------*/
@import 'header';
@import 'navigation';
@import 'sidebar';
@import 'section';